@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inconsolata', monospace;
}

body {
  font-family: 'Inconsolata', monospace;
  background: linear-gradient(90deg, #cfecd0, #a0cea7, #9ec0db);
  color: #555555;
  font-weight: 500;
}

/* .showfall {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none;
  background-image: url('./img/background1.png'), url('./img/background2.png'), url('./img/background3.png');
  background-image: url('./img/background2.png'),url('./img/background4.png');
  animation: snow 30s linear infinite;
} */

/* @keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }

  100% {
    background-position: 600px 800px, 600px 1000px, 600px 600px;
  }

} */

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  background-color: #ff3cac;
  background-image: linear-gradient(225deg, #ff3cac 0%, #784ba0 50%, #2b86c5 100%);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 15%;
  width: 35px;
  height: 35px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 40%;
  width: 40px;
  height: 40px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 20%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 50%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 90px;
  height: 90px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 80px;
  height: 80px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 67%;
  width: 35px;
  height: 35px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 5%;
  width: 45px;
  height: 45px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 55%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.18;
    border-radius: 0;
  }

  60% {
    border-radius: 50%;
  }

  80% {
    opacity: 0.1;
    border-radius: 50%;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}

.container {
  max-width: 800px;
  margin: 10px auto;
}

p,
h1 {
  margin: 0;
}

.block {
  position: relative;
  max-width: 420px;
  margin: auto;
  padding: 20px 9px 5px 9px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, .3);
  overflow: hidden;
}

.icon-flex {
  display: flex;
  gap: 5px;
  align-items: center;
}

.img-block,
.bg-loding-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -100;
}

.version {
  position: absolute;
  top: 2px;
  right: 7px;
  font-size: 10px;
}

.email-name {
  position: absolute;
  top: 45px;
  right: 11px;
  font-size: 11px;
  font-weight: 600;
}

.todo-h1 {
  font-size: 20px;
}

.header-all {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(85, 85, 85, 3);
  margin-bottom: 14px;
}

.todo-add-all {
  padding: 10px 0;
  display: flex;
  gap: 5px;
}

.todo-input {
  width: 70%;
  border: none;
  padding-left: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, .9);
}

.react-datepicker-popper {
  z-index: 999;
  height: 30px;
}

.react-datepicker__month-container {
  width: 260px;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 30px;
  line-height: 2;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: rgba(95, 195, 228, 0.9);
}

.react-datepicker__portal {
  background-color: rgba(0, 0, 0, 0);
  align-items: start;
  padding: 110px 0 0 100px;
}

.react-datepicker__header {
  padding: 0;
  font-size: 11px;
}

.react-datepicker__current-month {
  font-size: 18px !important;
}

.react-datepicker__current-month::first-letter {
  text-transform: uppercase;
}

.todo-date-edit {
  width: 93px;
  border: none;
  height: 30px;
  padding-left: 6px;
  background: rgba(255, 255, 255, .9);
  color: #555555;
  border: none;
  border-radius: 10px;
}

.date-controls {
  display: flex;
}

.block-view-all,
.block-month {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border-radius: 10px;
  padding: 5px;
  background: rgba(255, 255, 255, .9);
}

.lapa-img {
  position: absolute;
  bottom: 5px;
  right: 5px;
  opacity: 0.03;
  z-index: 0;
}

.todo-add {
  width: 30%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to right, #5ab8e0, #5aba8c);
}

.todo-add:hover {
  background-image: linear-gradient(to right, #4c9dc0, #4a9e75);
}

.block-date {
  font-size: 10px;
  padding: 0;
  margin-bottom: -3px;
}

.block-view-title {
  font-size: 13px;
  font-weight: 800;
}

.block-view,
.negative-value {
  display: flex;
  gap: 1px;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;
  border-radius: 5px;
  background: rgba(95, 195, 228, 0.6);
  z-index: 2;
}

.negative-value {
  background: rgba(255, 67, 29, 0.6);
}

.btn-delete {
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.btn-delete:hover,
.btn-delete:active {
  color: red;
  /* font-weight: bold; */
  transition: .5s;
  transform: rotate(90deg);
}

.total-title span {
  font-weight: 800;
  background: rgba(255, 255, 255, .7);
  border-radius: 5px;
  padding: 0 7px;
}

.mi-img {
  position: absolute;
  /* top: -5px; */
  top: -12px;
  left: 47%;
}

.block-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.chart-btn {
  cursor: pointer;
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 1px 5px;
}

.chart-btn:hover {
  font-weight: 600;
}

.chart-btn-active {
  cursor: pointer;
  font-weight: 600;
  background: rgba(255, 255, 255, .5);
  border: 1px solid gray;
  border-radius: 10px;
  padding: 1px 5px;
}

.block-sort-date-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-block-all,
.sort-block {
  text-align: right;
  font-size: 14px;
  margin: 0 2px 2px 0;
}

.sort-block {
  margin-left: 5px;
}

.sort-btn,
.plus-btn,
.minus-btn,
.show-btn {
  margin-right: 2px;
  font-size: 16px;
  border: none;
  background: inherit;
  color: #555555;
  cursor: pointer;
}

.sort-btn {
  margin-left: 7px;
}

.plus-btn,
.minus-btn,
.show-btn {
  padding: 0;
  border-radius: 50%;
  margin-right: 7px;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 20px;
}

.show-all-view-btn {
  margin-right: 7px;
  color: #555555;
  cursor: pointer;
}

.show-all-view-btn-delete {
  color: #555555;
  cursor: pointer;
  font-size: 15px;
}

.blue-view {
  color: #2b86c5;
}

.show-all-span {
  text-decoration: none;
  margin-right: 1px;
}

.sort-btn:hover,
.plus-btn:hover,
.minus-btn:hover,
.show-btn:hover {
  color: #4c9dc0;
}

.blue {
  color: #4c9dc0;
  background-color: rgba(255, 255, 255, 0.3);
}

.block-year-all {
  width: 100%;
}

.block-month-all {
  width: 100%;
}

.accordion-body {
  padding: 5px;
  background-color: none;
}

.accordion-header {
  width: 100%;
  border: none;
}

.fix-block-year {
  margin-top: 10px;
}

.accordion-button {
  padding: 5px;
  background: rgba(255, 255, 255, .7);
  margin-bottom: 2px;
}

.fix-date {
  text-align: right;
}

.fix-month {
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  background-color: none;
  background: rgba(255, 255, 255, .1);
}

.accordion-item {
  background-color: none;
  background: rgba(255, 255, 255, .4);
}

.total-year {
  font-weight: 500;
  margin-left: 5px;
}

.accordion-button:not(.collapsed) {
  background: rgba(95, 195, 228, 0.7);
}

.more {
  text-align: right;
  font-size: 12px;
}

.nav-pills {
  display: flex;
  justify-content: right;
}

.nav-link {
  color: #555555;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  font-weight: 500;
}

.nav-link:hover {
  color: #555555;
}

.dropdown-menu {
  background: rgba(255, 255, 255, .97);
  padding: 0;
  min-width: 80px;
  overflow: hidden;
  display: none;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
}

.dropdown-item:hover {
  background: rgba(95, 195, 228, 0.3);
}

.nav-link:focus {
  color: #555555;
  font-weight: 500;
}

.dropdown-divider {
  margin: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #555555;
  background: none;
}

.main-all-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

div[aria-labelledby="nav-dropdown-theme"].show {
  width: 320px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.nav-all-seting {
  display: flex;
  align-items: center;
  gap: 15px;
}

.about-block {
  transform: translateY(2px);
}

.loading {
  transform: translateY(-20px);
  text-align: center;
}

.login-name,
.about {
  background: inherit;
  border: none;
  color: #555555;
  font-weight: 500;
}

.login-name:hover {
  text-decoration: underline;
}

.img-nav-drop {
  width: 10px;
  height: 10px;
}

.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.sign-in-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 7px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #2a2a2a;
  cursor: pointer;
  text-align: center;
  border: none;
  border-radius: 10px;
  border: 1px solid gray;
  background: #f7f6f6;
  margin-top: 10px;
}

.sign-in-btn:hover {
  background: #fff;
}

.todo-h1-fix {
  text-decoration: underline;
}

.animate {
  animation-name: miAnimate;
  animation-duration: 1s;
}

.animate-mimi {
  animation-name: mimiAnimate;
  animation-duration: 20s;
}

.animate-meaw {
  animation-name: meawAnimate;
  animation-duration: 20s;
}

.meaw {
  position: absolute;
  top: 7px;
  right: 33%;
  opacity: 0;
}

.mimi {
  position: absolute;
  top: 0;
  right: 25%;
  transform: translateY(-40px);
}

.ava {
  width: 18px;
  opacity: .8;
  border-radius: 10px;
}

.logout-block {
  display: flex;
  align-items: center;
  gap: 5px;
}

.block-loding {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -40px 0;
}

.loding-img {
  width: 80px;
  transform: translateY(10px);
}

.download-block {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 7px;
  margin: 7px 5px 0 0;
}

.download-img {
  cursor: pointer;
  height: 22px;
  border-radius: 5px;
}

.download-img:hover {
  transform: scale(1.05);
}

@keyframes mimiAnimate {
  55% {
    transform: translateY(-40px);
  }

  70% {
    transform: translateY(0px);
  }

  85% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-40px);
  }
}

@keyframes meawAnimate {
  72% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  81% {
    opacity: 1;
  }

  84% {
    opacity: 0;
  }
}

@keyframes miAnimate {

  30% {
    transform: rotate(5deg);
  }

  70% {
    transform: rotate(-5deg);
  }
}